import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

emailjs.init("22vQpj0xZ9jldM1_w");

const Result = () => {
    return (
        <p className="success-message">
            Your Message has been successfully sent. We will contact you soon.
        </p>
    );
};
function ContactForm({ props }) {
    const form = useRef();
    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm("service_kkzkb7i", "template_k1658b8", form.current)
            .then(
                (result) => {
                    console.log("success", result.text);
                    showresult(true);
                    setTimeout(() => {
                        showresult(false);
                    }, 5000);
                },
                (error) => {
                    console.log("error", error.text);
                }
            );
        e.target.reset();
    };

    return (
        <form ref={form} action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                />
            </div>

            <div className="rn-form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                ></textarea>
            </div>

            <div className="rn-form-group">
                <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                >
                    Submit Now
                </button>
            </div>

            <div className="rn-form-group">{result ? <Result /> : null}</div>
        </form>
    );
}
export default ContactForm;
