import blog1 from "../../assets/images/blog/blog-01.jpg";
import blog2 from "../../assets/images/blog/blog-02.jpg";
import blog3 from "../../assets/images/blog/blog-03.jpg";
import blog4 from "../../assets/images/blog/blog-04.jpg";

const BlogContent = [
    {
        images: blog1,
        title: " Getting tickets to the big show",
        category: "Development",
    },
    {
        images: blog2,
        title: "A big ticket gone to be an interesting ",
        category: "Management",
    },

    {
        images: blog3,
        title: "The Home of the Future Could Bebes",
        category: "Design",
    },
    {
        images: blog4,
        title: " Getting tickets to the big show",
        category: "Development",
    },
];

export default BlogContent;
