import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import BreadcrumbOne from "../elements/common/BreadcrumbOne";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import about from "../assets/images/service/service-0.jpg";
const ServiceList = [
  {
    icon: <FiCast />,
    title: "Business Stratagy",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiLayers />,
    title: "Website Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiUsers />,
    title: "Marketing & Reporting",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiMonitor />,
    title: "Mobile App Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiCast />,
    title: "Website Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiMonitor />,
    title: "Marketing & Reporting",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
];
class Service extends Component {
  render() {
    let title = "Service",
      description =
        "At Astra Innovations LLC, we leverage the power of innovation to shape a sustainable and transformative future across various industries. We provide high-quality solar and battery cables, ensuring reliable performance and efficiency for all your needs. Additionally, we are in the process of developing an app designed to help our next generation. Our mission is to unlock new potentials and create a future where technology and sustainability seamlessly intersect. We invite you to join us on this journey of discovery, growth, and innovation. Together, we are moving towards a brighter, more sustainable tomorrow.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Service" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <BreadcrumbOne title={"Service"} />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        {/* <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Renewable Energy</h2>
                                    <p>
                                        There are many variations of passages of
                                        Lorem Ipsum available, <br /> but the
                                        majority have suffered alteration.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div
                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                                    key={i}
                                >
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">
                                                    {val.title}
                                                </h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start Service Area */}
        {/* <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Artificial Intellgience</h2>
                                    <p>
                                        There are many variations of passages of
                                        Lorem Ipsum available, <br /> but the
                                        majority have suffered alteration.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div
                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                                    key={i}
                                >
                                    <a
                                        className="text-center"
                                        href="/service-details"
                                    >
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">
                                                    {val.title}
                                                </h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start Slider Area   */}
        {/* <div className="slider-wrapper">
                    <div className="slider-activation slider-startup">
                        <Slider
                            className="rn-slick-dot dot-light"
                            {...slideSlick}
                        >
                            {ServiceList.map((value, index) => (
                                // Start Single Slider
                                <div
                                    className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                                    key={index}
                                    data-black-overlay="8"
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div
                                                    className={`inner ${value.textPosition}`}
                                                >
                                                    {value.title ? (
                                                        <h1 className="title theme-gradient">
                                                            {value.title}
                                                        </h1>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {value.description ? (
                                                        <p className="description">
                                                            {value.description}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {value.buttonText ? (
                                                        <div className="slide-btn">
                                                            <a
                                                                className="rn-button-style--2 btn-primary-color"
                                                                href={`${value.buttonLink}`}
                                                            >
                                                                {
                                                                    value.buttonText
                                                                }
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                // End Single Slider
                            ))}
                        </Slider>
                    </div>
                </div> */}
        {/* End Slider Area   */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={about} alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    {/* <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">
                                                        What we can provide
                                                    </h3>
                                                    <p>
                                                        We are dedicated to
                                                        developing
                                                        groundbreaking software
                                                        and providing a
                                                        selection of online
                                                        products that embody
                                                        sustainability and
                                                        innovation.
                                                        Additionally, Our
                                                        platform serves as a
                                                        vital source of news and
                                                        insights into the latest
                                                        trends and breakthroughs
                                                        in innovative fields. By
                                                        integrating this
                                                        informational hub into
                                                        our services, we aim to
                                                        not only shape a
                                                        sustainable and
                                                        intelligent future but
                                                        also to keep our
                                                        audience informed and
                                                        engaged with the
                                                        evolving landscape of
                                                        innovation.
                                                    </p>
                                                </div>
                                            </div>
                                            {/*<div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">
                                                        Who we are
                                                    </h3>
                                                    <p>
                                                        There are many vtions of
                                                        passages of Lorem Ipsum
                                                        available, but the
                                                        majority have suffered.
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Service;
