import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
// import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

const SlideList = [
  {
    textPosition: "text-center",
    bgImage: "bg_image--15",
    title: "Innovate with Astra",
    category: "",
    description: "",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--12",
    title: "Innovate with Astra",
    category: "",
    description: "",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--13",
    title: "Innovate with Astra",
    category: "",
    description: "",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

class Home extends Component {
  render() {
    const PostList = BlogContent.slice(0, 3);
    return (
      <Fragment>
        <Helmet pageTitle="Home" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation slider-startup">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                // Start Single Slider
                <div
                  className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="5"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1
                              className="title theme-gradient"
                              style={{ fontSize: "50px" }}
                            >
                              {value.title}
                            </h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-primary-color"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                // End Single Slider
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area ptb--120">
          <AboutTwo />
        </div>
        {/* End About Area */}
        <div className="service-area ptb--75">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>

        {/* Start Portfolio Area */}
        {/* <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div> */}
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        {/* <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500 mb--10">
                                        Our Fun Facts
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        {/*}
                <div className="rn-blog-area pt--120 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left">
                                    <h2>Latest News</h2>
                                    <p>
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a
                                        className="btn-transparent rn-btn-dark"
                                        href="/blog"
                                    >
                                        <span className="text">
                                            View All News
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            {PostList.map((value, i) => (
                                <div
                                    className="col-lg-4 col-md-6 col-12"
                                    key={i}
                                >
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            {/* <a href="/blog-details">
                                                <img
                                                    className="w-100"
                                                    src={value.images}
                                                    alt="Blog Images"
                                                />
                                            {/* </a> 
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">
                                                {value.category}
                                            </p>
                                            <h4 className="title">
                                                <a href="/blog-details">
                                                    {value.title}
                                                </a>
                                            </h4>
                                            <div className="blog-btn">
                                                {/* <a
                                                    className="rn-btn text-white"
                                                    href="/blog-details"
                                                >
                                                    Read More
                                                </a> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                */}
        {/* End Blog Area */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </Fragment>
    );
  }
}
export default Home;
