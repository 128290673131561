import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/service-01.png";
import serviceImg2 from "../assets/images/service/service-02.png";
import serviceImg4 from "../assets/images/service/service-04.png";
import serviceImg5 from "../assets/images/service/service-05.png";
import serviceImg6 from "../assets/images/service/service-06.png";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Solar Energy</h2>
                  <div className="service-btn mt--20">
                    <a href="contact" className="rn-btn btn-primary-color">
                      Get Quote
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <Carousel
                            autoPlay={true}
                            showThumbs={false}
                            showStatus={false}
                            showArrows={false}
                            swipeable={true}
                            width="100%"
                            infiniteLoop={true}
                            interval={5000}
                          >
                            <div>
                              <img
                                className="w-100"
                                src={serviceImg}
                                alt="Service Images"
                              />
                            </div>
                            <div>
                              <img
                                className="w-100"
                                src={serviceImg2}
                                alt="Service Images"
                              />
                            </div>
                            <div>
                              <img
                                className="w-100"
                                src={serviceImg4}
                                alt="Service Images"
                              />
                            </div>
                            <div>
                              <img
                                className="w-100"
                                src={serviceImg5}
                                alt="Service Images"
                              />
                            </div>
                          </Carousel>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Quality and Value</h4>
                          <p>
                            Astra Innovations sets itself apart by offering
                            high-quality, US-made solar and battery cables with
                            exceptional durability and fast turnaround times.
                            Our cables are built to withstand harsh UV
                            conditions, ensuring maximum lifespan. We provide a
                            significant cost advantage compared to other
                            US-based competitors and maintain an extensive
                            inventory with a wide range of gauges and lengths
                            (from 14 AWG to 2 AWG). All our products are UL
                            Listed and adhere to rigorous international safety
                            standards, guaranteeing safety and compliance for
                            every project.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            Reliable and Custom Solutions
                          </h4>
                          <p>
                            Beyond our top-tier products, Astra Innovations
                            supports your success through a reliable supply
                            chain, efficient assembly, and timely deliveries.
                            Our knowledgeable team is always available to offer
                            dedicated support and expert assistance.
                            Additionally, we offer customizable solutions,
                            designing and manufacturing cables to meet your
                            exact specifications. Partner with us to benefit
                            from our quality, reliability, and tailored solution
                            that ensure your project's success.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg6}
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() =>
                              this.setState({
                                isOpen: false,
                              })
                            }
                          />
                          {/* <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>*/}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
