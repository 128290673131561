import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ServiceList = [
    {
        icon: <FiCast />,
        title: "Service 1",
        description: "HEHE XD",
    },
    {
        icon: <FiLayers />,
        title: "Service 2",
        description: "HEHE XD",
    },
    {
        icon: <FiUsers />,
        title: "Service 3",
        description: "HEHE XD",
    },
    {
        icon: <FiMonitor />,
        title: "Service 4",
        description: "HEHE XD",
    },
];

class ServiceTwo extends Component {
    render() {
        let title = "Services",
            description =
                "We are dedicated to two main areas:  Developing cutting-edge applications \
                and distributing high-quality solar products. Our primary focus is on app development,\
                where we create innovative, user-friendly solutions to enhance educational experiences. \
                Additionally, we provide reliable and efficient solar products to \
                support sustainable energy initiatives. Would you like to know more? \
                Explore our services and join us in our mission to integrate technology and sustainability for a better future.";
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn mt--20">
                                <Link
                                    to="/service"
                                    className="rn-btn btn-primary-color"
                                >
                                    Learn More
                                </Link>{" "}
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                >
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">
                                                    {val.title}
                                                </h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>*/}
                </div>
            </React.Fragment>
        );
    }
}
export default ServiceTwo;
