import React, { Component } from "react";
import { Link } from "react-router-dom";

import about from "../../../assets/images/about/about-3.jpg";

class AboutTwo extends Component {
    render() {
        let title = "About",
            description =
                "At Astra Innovations LLC, we harness innovation's power to shape the future, \
                focusing on sustainable and transformative advancements in diverse industries. \
                Our goal is to unlock new potentials, contributing to a future where technology and \
                sustainability intersect harmoniously. By actively building this future, we invite you to \
                join our journey of discovery, growth, and innovation. With each step forward, \
                we leap towards a brighter, more sustainable tomorrow.";
        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img
                                        className="w-100"
                                        src={about}
                                        alt="About Images"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            {description}
                                        </p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div>
                                                <Link
                                                    to="/about"
                                                    className="rn-btn btn-primary-color"
                                                >
                                                    Learn More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default AboutTwo;
