import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import testimonialImg1 from "../assets/images/client/testimonial-1.jpg";
import testimonialImg2 from "../assets/images/client/testimonial-2.jpg";
import testimonialImg3 from "../assets/images/client/testimonial-3.jpg";
import testimonialImg4 from "../assets/images/client/testimonial-4.jpg";
import testimonialImg5 from "../assets/images/client/testimonial-5.jpg";
import testimonialImg6 from "../assets/images/client/testimonial-6.jpg";
import testimonialImg7 from "../assets/images/client/testimonial-7.jpg";
import testimonialImg8 from "../assets/images/client/testimonial-8.jpg";

class Testimonial extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            I had an excellent experience with
                                            my purchase! The item was exactly as
                                            described, and the shipping was
                                            incredibly fast. The transaction
                                            went smoothly with no problems at
                                            all. I highly recommend them—A+++
                                            service! Thank you, I'm very happy
                                            with my purchase.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>-A Satisfied Customer </span>
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel>
                            {/*                         <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            Astra Innovations LLC has been
                                            instrumental in transforming our
                                            business. Their innovative
                                            solutions, tailored approach, and
                                            outstanding customer service have
                                            exceeded our expectations. Highly
                                            recommended for anyone seeking
                                            cutting-edge technology and
                                            strategic insights.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>Jhon Doe </span>
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            Astra Innovations LLC has been
                                            instrumental in transforming our
                                            business. Their innovative
                                            solutions, tailored approach, and
                                            outstanding customer service have
                                            exceeded our expectations. Highly
                                            recommended for anyone seeking
                                            cutting-edge technology and
                                            strategic insights.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>Jhon Doe </span> - Satisfied
                                            Customer
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            Astra Innovations LLC has been
                                            instrumental in transforming our
                                            business. Their innovative
                                            solutions, tailored approach, and
                                            outstanding customer service have
                                            exceeded our expectations. Highly
                                            recommended for anyone seeking
                                            cutting-edge technology and
                                            strategic insights.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>Jhon Doe </span> - Satisfied
                                            Customer
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            Astra Innovations LLC has been
                                            instrumental in transforming our
                                            business. Their innovative
                                            solutions, tailored approach, and
                                            outstanding customer service have
                                            exceeded our expectations. Highly
                                            recommended for anyone seeking
                                            cutting-edge technology and
                                            strategic insights.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>Jhon Doe </span> - Satisfied
                                            Customer
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel>

                            {/* <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            Chunk of Lorem Ipsum used since the
                                            1500s is reproduced below for those
                                            interested. Sections Bonorum et
                                            Malorum original.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>Amar Orthi </span> - COO,
                                            AMERIMAR ENTERPRISES, INC.
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            The standard chunk of Lorem Ipsum
                                            used since the 1500s is reproduced
                                            below for those interested. Sections
                                            Bonorum et Malorum original.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>Fatima Ma </span> - COO,
                                            AMERIMAR ENTERPRISES, INC.
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            Lorem Ipsum used since the 1500s is
                                            reproduced below for those
                                            interested. Sections Bonorum et
                                            Malorum original.
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>
                                            <span>JON CUMMINS </span> - COO,
                                            AMERIMAR ENTERPRISES, INC.
                                        </h6>
                                    </div>
                                </div>
                            </TabPanel> */}
                            {/*
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg1}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab>
                                
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg2}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg3}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg4}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg5}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab>
                                {/* <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg6}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab> */}
                            {/* <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg7}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab> */}
                            {/* <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img
                                                src={testimonialImg8}
                                                alt="Testimonial Images"
                                            />
                                        </div>
                                    </div>
                                </Tab> 
                            </TabList> */}
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Testimonial;
