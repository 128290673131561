import React, { useState, useEffect } from "react";
import "../../assets/scss/header/mcFormStyles.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import InputField from "./InputField";

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
            firstName &&
            lastName &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
                MERGE1: firstName,
                MERGE2: lastName,
            });
    };

    useEffect(() => {
        if (status === "success") clearFields();
    }, [status]);

    const clearFields = () => {
        setEmail("");
        setFirstName("");
        setLastName("");
    };

    return (
        <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
            <h3 className="mc__title">
                {status === "success"
                    ? "Thank you for subscribing!"
                    : "Subscribe to our newsletter!"}
            </h3>

            {status === "sending" && (
                <div className="mc__alert mc__alert--sending">Sending...</div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            <div className="mc__field-container">
                <InputField
                    label="First Name"
                    onChangeHandler={setFirstName}
                    type="text"
                    value={firstName}
                    placeholder="First Name"
                    isRequired
                />

                <InputField
                    label="Last Name"
                    onChangeHandler={setLastName}
                    type="text"
                    value={lastName}
                    placeholder="Last Name"
                    isRequired
                />

                <InputField
                    label="Email"
                    onChangeHandler={setEmail}
                    type="email"
                    value={email}
                    placeholder="your@email.com"
                    isRequired
                />
            </div>

            <InputField
                label="subscribe"
                type="submit"
                formValues={[email, firstName, lastName]}
            />
        </form>
    );
};

const MailChimpFormContainer = (props) => {
    const postUrl = `https://astrainnovationsllc.us18.list-manage.com/subscribe/post?u=${process.env.React_APP_MAILCHIMP_U}&id=${process.env.React_APP_MAILCHIMP_ID}`;
    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};

export default MailChimpFormContainer;
