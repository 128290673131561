import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import about from "../assets/images/about/about-10.jpg";
import findingImg from "../assets/images/about/learnMore.jpg";
import teamImg1 from "../assets/images/team/team-01.jpg";
import teamImg2 from "../assets/images/team/team-02.jpg";
import teamImg3 from "../assets/images/team/team-03.jpg";
import teamImg4 from "../assets/images/team/team-04.jpg";

class About extends Component {
  render() {
    let title = "About",
      description =
        "At Astra Innovations LLC, we harness innovation's power to shape the future, \
                focusing on sustainable and transformative advancements in diverse industries. \
                Our goal is to unlock new potentials, contributing to a future where technology and sustainability \
                intersect harmoniously. \
                By actively building this future, we invite you to join our journey of discovery, \
                growth, and innovation. With each step forward, we leap towards a brighter,\
                 more sustainable tomorrow.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={about} alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">What we can provide</h3>
                          <p>
                            We are dedicated to developing innovative,
                            user-friendly application to enhance educational
                            experiences and providing a selection of
                            high-quality solar products that embody
                            sustainability and innovation Additionally, Our
                            platform serves as a vital source of news and
                            insights into the latest trends and breakthroughs in
                            innovative fields. By integrating this informational
                            hub into our services, we aim to not only shape a
                            sustainable and intelligent future but also to keep
                            our audience informed and engaged with the evolving
                            landscape of innovation.
                          </p>
                        </div>
                      </div>
                      {/*<div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">
                                                        Who we are
                                                    </h3>
                                                    <p>
                                                        There are many vtions of
                                                        passages of Lorem Ipsum
                                                        available, but the
                                                        majority have suffered.
                                                    </p>
                                                </div>
                                            </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        {/*<div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">
                                        Our Fun Facts
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>*/}
        {/* End CounterUp Area */}

        {/* Start Team Area  */}
        <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Skilled Team</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img className="w-100" src={teamImg1} alt="Blog Images" />
                  </div>
                  <div className="content">
                    <h4 className="title">Leif Johnston</h4>
                    <p className="designation">CEO</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img className="w-100" src={teamImg2} alt="Blog Images" />
                  </div>
                  <div className="content">
                    <h4 className="title">Tianyin Zhang</h4>
                    <p className="designation">Full Stack Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="http://linkedin.com/in/tianyinzhang37">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img className="w-100" src={teamImg3} alt="Blog Images" />
                  </div>
                  <div className="content">
                    <h4 className="title">Shan He</h4>
                    <p className="designation">Machine Learning Engineer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.linkedin.com/in/shan-he-369119268/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img className="w-100" src={teamImg4} alt="Blog Images" />
                  </div>
                  <div className="content">
                    <h4 className="title">Justin Wang</h4>
                    <p className="designation">
                      Business Development Assistant
                    </p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.linkedin.com/in/justinwang2023/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Finding Us Area  */}
        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h4 className="theme-gradient">Learn more about our work</h4>
                <p>
                  Would you like to know more? Explore our services using the
                  'Get started' button below.
                </p>
                <a className="rn-btn btn-white" href="/service">
                  Get Started
                </a>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img src={findingImg} alt="Finding Images" />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Testimonial Area */}
        {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
